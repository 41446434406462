
import useEmitter from "@/composables/Emmiter";
import { getDataDayAccessed } from "@/services/LogAcessosList";
import { onMounted, ref } from "vue";
import { useStore } from 'vuex';
import { TableDiaAcessado } from '@/models/LogsModel'
import { ContentLoader } from 'vue-content-loader';
import moment from 'moment';

interface filter {
    codUsuario: string,
    mes: string,
}

interface totalLogAcesso {
    totalAcesso: number,
    cor: string
}

export default {
    name: 'log-lista',
    components: {
        ContentLoader
    },
    setup() {
        const store = useStore();
        const emitter = useEmitter();
        const loading = ref(false)
        const codUsuario = store.getters.usuarioLogado.codUsuario;
        const dataAtual = new Date();
        const numeroDoMesAtual = dataAtual.getMonth() + 1;
        const tableAcesso = ref<TableDiaAcessado[]>([])
        const userName = ref('')
        const totalAcesso = ref<totalLogAcesso>()
        const monthSelected = ref('')

        emitter.on("filter-log-user-list", async (data: filter) => {
            dataDayAccessedUser(data)
        })

        onMounted(() => {
            dataDayAccessedUser({
                codUsuario: codUsuario,
                mes: numeroDoMesAtual.toString()
            })
        })

        const dataDayAccessedUser = async (data: filter): Promise<void> => {
            loading.value = true
            monthSelected.value = nameMonth(data.mes)
            const { logAcessoDiario, nomeUsuario, totalLogAcesso } = await getDataDayAccessed(data)
            tableAcesso.value = logAcessoDiario
            userName.value = nomeUsuario
            totalAcesso.value = totalLogAcesso
            loading.value = false
        }

        const nameMonth = (mes: string): string => {
            const monthSelect = moment({ month: Number(mes) - 1, day: 1 })
            monthSelect.locale('pt-br')
            const nomeDoMes = monthSelect.format('MMMM');

            return nomeDoMes
        }

        return {
            loading,
            tableAcesso,
            userName,
            totalAcesso,
            monthSelected
        }
    }
}
