import { GetUsuariosSelect, ListagemDiaAcessado} from "@/models/LogsModel";
import ApiService from "./ApiService";

/**
 * @description usuarios select filtro
 */

const getUserSelect = async (payload) : Promise<GetUsuariosSelect[]> => {
    const { data } = await ApiService.post(`/logUsuario/usuario`,payload);
    return data;
};

const getDataDayAccessed = async (payload) : Promise<ListagemDiaAcessado> => {
    const { data } = await ApiService.post(`/logUsuario`,payload);
    return data;
};

export {getUserSelect,getDataDayAccessed }